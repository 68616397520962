/* General Layout */
.store-listings {
  padding: 20px;
  max-width: 800px; /* Set a fixed width for readability */
  margin: 0 auto;
  text-align: center;
  overflow-y: auto;
}

/* Store Header Styling */
.store-details h2 {
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 10px;
  color: #333;
}

.store-details p {
  font-size: 1.1rem;
  color: #666;
  margin-bottom: 20px;
}

/* WhatsApp Button Styling */
.whatsapp-button {
  width: 60px;
  height: 60px;
  margin: 10px 0;
  transition: transform 0.3s ease;
}

.whatsapp-button:hover {
  transform: scale(1.1);
}


/* WazeLogo Button Styling */
.wazelogo-button {
  width: 60px;
  height: 60px;
  margin: 10px 0;
  transition: transform 0.3s ease;
}

.wazelogo-button:hover {
  transform: scale(1.1);
}




/* Copy Button Container */
.copy-button-container {
  margin: 15px 0;
}

/* Copy Button Styling */
.copy-button {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  background-color: #2a9d8f;
  color: white;
  font-weight: bold;
  font-size: 1rem;
  border: none;
  border-radius: 5px;
  padding: 10px 15px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.copy-button:hover {
  background-color: #21867a;
  transform: scale(1.05);
}

.copy-icon {
  font-size: 1.2rem;
}

.copy-checkmark {
  color: #ffffff;
  font-size: 1.2rem;
  margin-right: 5px;
}

/* Product Grid Layout */
.product-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr)); /* Responsive columns */
  gap: 20px; /* Spacing between cards */
  margin-top: 20px;
}

/* Product Item Styling */
.product-item {
  background: #fff;
  border: 1px solid #ddd;
  border-radius: 10px;
  padding: 20px;
  text-align: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s ease, transform 0.3s ease;
}

.product-item:hover {
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  transform: translateY(-5px);
}

.product-image {
  width: 100%;
  height: 150px;
  object-fit: cover;
  border-radius: 8px;
  margin-bottom: 10px;
}

.product-item h4 {
  font-size: 1.4rem;
  font-weight: bold;
  margin-bottom: 5px;
}

.product-item p {
  font-size: 1.1rem;
  color: #555;
}

/* Map styling */
.map-container {
  border: 2px solid #ccc; /* Thicker border for emphasis */
  border-radius: 15px; /* More rounded corners for a modern look */
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1); /* Add a subtle shadow for depth */
  overflow: hidden; /* Hide overflow for rounded corners */
  margin-top: 20px; /* Add some space above the map */
  transition: transform 0.3s ease, box-shadow 0.3s ease; /* Smooth transition for hover effects */
}

.map-container:hover {
  transform: scale(1.02); /* Slight zoom effect */
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.15); /* Enhance shadow on hover */
}

.map-button {
  width: 70px;
  height: 40px;
  object-fit: cover;
  margin-bottom: 20px;
}

/* Mobile-specific styles */
@media (max-width: 600px) {
  .store-details h2 {
    font-size: 1.8rem; /* Adjust font size for smaller screens */
  }

  .store-details p {
    font-size: 1rem;
  }

  .product-image {
    height: 120px; /* Adjust height for mobile */
  }

  .product-item {
    padding: 15px;
  }

  .copy-button {
    font-size: 0.9rem; /* Adjust button font size for mobile */
    padding: 8px 12px; /* Adjust button padding for mobile */
  }

  .copy-icon,
  .copy-checkmark {
    font-size: 1rem;
  }

  .whatsapp-button {
    width: 50px;
    height: 50px;
  }

  .wazelogo-button {
    width: 50px;
    height: 50px;
  }
}
