/* Global Reset */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: Arial, sans-serif;
  background-color: #f9f9f9;
  color: #333;
}

.app-container {
  position: relative;
  min-height: 100vh;
}

/* Navbar Styles */
.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #ffffff;
  padding: 1rem 2rem;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  position: sticky;
  top: 0;
  z-index: 1000;
}

.navbar-logo {
  font-size: 1.5rem;
  font-weight: bold;
  color: #2a9d8f;
  text-decoration: none;
}

.navbar-links {
  display: flex;
  list-style: none;
  gap: 1.5rem;
  margin: 0;
}

.navbar-links li a {
  text-decoration: none;
  color: #333;
  font-size: 1rem;
  font-weight: 500;
  transition: color 0.3s ease;
}

.navbar-links li a:hover {
  color: #2a9d8f;
}

.vendor-button {
  width: 100px; /* Fixed width */
  height: 20px; /* Fixed height */
  padding: 8px 16px; /* Padding as per the diagram */
  background-color: #2a9d8f; /* Button background */
  color: #fff; /* Text color */
  border: none; /* Remove border */
  border-radius: 4px; /* Rounded corners */
  text-align: center; /* Center text */
  font-size: 0.9rem; /* Adjusted font size */
  font-weight: bold; /* Bold text */
  line-height: calc(20px - 16px); /* Adjust line height for text fit */
  display: flex; /* Flexbox centering */
  justify-content: center; /* Horizontal centering */
  align-items: center; /* Vertical centering */
  transition: background-color 0.3s ease; /* Smooth transition */
}

.vendor-button:hover {
  background-color: #21867a; /* Hover state */
}

/* Input and Textarea Styling */
input,
textarea {
  font-size: 16px; /* Prevents zooming on mobile */
  -webkit-text-size-adjust: 100%; /* Prevent text resizing on iOS */
  box-sizing: border-box;
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 8px;
  outline: none;
  background-color: #fff;
  transition: border-color 0.3s, box-shadow 0.3s;
}

input:focus,
textarea:focus {
  border-color: #2a9d8f; /* Matches theme color */
  box-shadow: 0 0 4px rgba(42, 157, 143, 0.5);
}

textarea {
  resize: none; /* Disable resizing for textareas */
}

/* Responsive Styles */
@media (max-width: 768px) {
  .navbar {
    flex-direction: row; /* Keep items in a row */
    padding: 1rem;
  }

  .navbar-logo {
    font-size: 1.2rem; /* Adjust logo size for mobile */
  }

  .navbar-links {
    gap: 1rem; /* Reduce gap for mobile */
  }

  .navbar-links li a {
    font-size: 0.9rem; /* Adjust text size for mobile */
  }

  .vendor-button {
    width: 100px; /* Keep fixed width */
    height: 20px; /* Keep fixed height */
    padding: 8px 16px; /* Maintain padding */
    font-size: 0.8rem; /* Slightly smaller text size */
  }
}
