/* Search Bar Container */
.search-bar-container {
  margin-top: 30px;
  position: relative;
  width: 100%;
  max-width: 300px; /* Set a max width for consistency */
  margin: 0 auto; /* Center the search bar */
  box-sizing: border-box; /* Ensure padding does not affect width */
  z-index: 10; /* Higher z-index to stay above the map */
}

/* Styling for the Search Input */
.search-bar-input {
  width: 100%;
  padding: 10px; /* Adequate padding for usability */
  font-size: 16px; /* Minimum font size to avoid mobile zoom issues */
  border: 2px solid #00d0ff; /* Brighter border for emphasis */
  border-radius: 6px; /* Slightly larger border-radius for better aesthetics */
  outline: none; /* Remove default outline */
  transition: border-color 0.3s ease, box-shadow 0.3s ease; /* Smooth transition effects */
  box-sizing: border-box; /* Prevent padding from affecting width */
  background-color: #ffffff; /* Clean background */
}

/* Search Bar Focus State */
.search-bar-input:focus {
  border-color: #f0a500; /* Highlight the border when focused */
  box-shadow: 0 0 5px rgba(240, 165, 0, 0.3); /* Add slight shadow for focus effect */
}

/* Search Results Container */
.search-results {
  position: absolute; /* Positioned relative to the input */
  top: 100%; /* Directly below the input */
  left: 0;
  width: 100%;
  max-height: 200px; /* Limit height to prevent overflow */
  overflow-y: auto; /* Add scroll if results exceed max height */
  background-color: #ffffff; /* Clean background */
  border: 1px solid #ccc; /* Light border for separation */
  border-radius: 4px; /* Rounded corners for aesthetics */
  margin-top: 5px; /* Space between input and results */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
  z-index: 20; /* Ensure results stay on top of other elements */
}

/* Search Result Item */
.search-result-item {
  padding: 10px; /* Adequate padding for touch interaction */
  border-bottom: 1px solid #eee; /* Separate results visually */
  cursor: pointer; /* Indicate that the result is clickable */
}

.search-result-item:hover {
  background-color: #f9f9f9; /* Add hover effect for better UX */
}

/* No Results Message */
.no-results {
  padding: 10px;
  text-align: center;
  color: #999; /* Gray text for subdued message */
  font-style: italic; /* Italic to distinguish from results */
}

/* Responsive Adjustments for Mobile */
@media (max-width: 768px) {
  .search-bar-container {
    padding: 0 10px; /* Less padding for mobile */
  }

  .search-bar-input {
    font-size: 16px; /* Keep font size at least 16px to prevent zoom */
    padding: 8px; /* Reduce padding slightly for smaller screens */
  }
}

/* Search Bar Positioning on Map Screen */
.search-bar-container {
  position: absolute; /* Absolute positioning for overlay */
  top: 35px; /* Adjust position relative to the screen or navbar */
  left: 50%; /* Center horizontally */
  transform: translateX(-50%); /* Proper centering */
  z-index: 10; /* Ensure it is above the map */
}

/* Animation for Search Results (Optional) */
.search-results {
  animation: fadeIn 0.3s ease; /* Smooth fade-in effect for results */
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-10px); /* Slight slide-up effect */
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
