.mapbox-container {
    width: 100vw; /* Full viewport width */
    height: 100vh; /* Full viewport height */
    position: absolute; /* Position the map to cover the entire screen */
    top: 0;
    left: 0;
    z-index: 0; /* Ensure the map is behind other elements */
  }
  
  .popup-card-container {
    font-family: Arial, sans-serif;
    font-size: 14px;
    line-height: 1.4;
  }
  
  .popup-card-container img {
    width: 100%;
    height: auto;
    border-radius: 4px;
    margin-bottom: 0.5rem;
  }
  
  .popup-card-container button {
    display: inline-block;
    padding: 5px 10px;
    background-color: #2a9d8f;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 14px;
  }
  
  .popup-card-container button:hover {
    background-color: #21867a;
  }
  