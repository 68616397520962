/* Side Panel container */
.side-panel {
  position: fixed;
  top: 0;
  right: -100%; /* Start hidden off-screen */
  width: 50%; /* 50% width on desktop */
  height: 100%;
  background-color: white;
  box-shadow: -4px 0 8px rgba(0, 0, 0, 0.2);
  transition: right 0.4s ease-in-out, width 0.3s ease;
  z-index: 100;
  display: flex;
  flex-direction: column;
  padding: 20px; /* Add padding for modern look */
  border-radius: 8px 0 0 8px; /* Rounded corner on the left for modern look */
}

/* Open state for side panel */
.side-panel.open {
  right: 0; /* Slide in */
}

/* Close button styles */
.close-btn {
  position: absolute;
  top: 50px; /* Lower the position slightly */
  right: 30px; /* Right padding to match */
  background-color: transparent;
  border: none;
  font-size: 24px;
  cursor: pointer;
  color: #333;
  padding: 10px;
  border-radius: 8px;
  outline: none;
  transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
}

/* Hide close button when the panel is closed */
.side-panel .close-btn {
  opacity: 0;
}

/* Show close button when the panel is open */
.side-panel.open .close-btn {
  opacity: 1;
}

/* Country list styling */
.country-list {
  padding: 20px;
  list-style-type: none;
  flex-grow: 1;
  overflow-y: auto; /* Scrollable country list */
  max-height: calc(100vh - 60px); /* Restrict height for modern look */
}

/* Country item */
.country-item {
  padding: 10px;
  cursor: pointer;
  border-bottom: 1px solid #ddd;
  transition: background-color 0.2s ease;
  font-size: 18px; /* Modern larger font */
}


/* Mobile adjustments */
@media (max-width: 768px) {
  .side-panel {
    width: 100%; /* Full width on mobile */
    right: -100%;
  }

  .side-panel.open {
    right: 0;
    width: 100%;
  }
}
