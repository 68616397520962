/* Map Screen */
.map-screen {
  position: fixed; /* Fix to viewport */
  top: 0; /* Start at the very top of the screen */
  left: 0;
  width: 100vw; /* Full width */
  height: 100vh; /* Full height */
  overflow: hidden; /* Disable scrolling */
  display: flex; /* Use flex to ensure child elements align properly */
  flex-direction: column; /* Align children vertically */
}

/* Navbar (keep as is) */
.navbar {
  position: fixed; /* Fixed to the top */
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
  background-color: #ffffff;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  padding: 1rem 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

/* Map Container */
.map-container {
  position: absolute; /* Positioned relative to parent */
  top: 40px; /* Set to navbar height */
  left: 0;
  width: 100%;
  height: calc(100vh - 80px); /* Subtract navbar height from total screen height */
  overflow: hidden; /* Ensure no scrollbars appear */
  z-index: 0; /* Below search bar and side panel */
}

/* Search Bar */
.search-bar-container {
  position: absolute;
  top: 35px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 10;
}

/* Floating Burger Icon */
.floating-burger-btn {
  position: absolute;
  top: 65px;
  right: 20px;
  z-index: 15;
  background-color: #2a9d8f;
  color: white;
  border: none;
  border-radius: 50%;
  padding: 0.8rem;
  font-size: 1.2rem;
  cursor: pointer;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}
