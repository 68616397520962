/* General Styles */
body {
  margin: 0;
  padding: 0;
  font-family: 'Arial', sans-serif;
  overflow-x: hidden;
  background-color: #f8f9fa;
}

.home-page {
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* Hero Section */
.hero-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 2rem 1rem;
  background: linear-gradient(135deg, #2a9d8f, #21867a); /* Gradient background */
  color: white; /* Ensure all text is white */
  width: 100%;
  height: 50vh; /* Adjusted height for mobile-first design */
}

.hero-content h1 {
  font-size: 2rem; /* Mobile-first font size */
  margin-bottom: 1rem;
  font-weight: bold;
  color: white; /* White text for high contrast */
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2); /* Subtle shadow for text clarity */
}

.hero-content p {
  font-size: 1rem;
  margin-bottom: 1.5rem;
}

.signup-button {
  padding: 0.8rem 1.5rem;
  background-color: #fff;
  color: #2a9d8f;
  border: none;
  border-radius: 8px;
  font-size: 1rem;
  font-weight: bold;
  text-decoration: none;
  transition: background-color 0.3s, color 0.3s;
  cursor: pointer;
}

.signup-button:hover {
  background-color: #21867a;
  color: white;
}

/* Vendor List Section */
.vendor-list-section {
  padding: 1.5rem 1rem;
  background-color: #fff;
  text-align: center;
  width: 100%;
}

.vendor-list-section h2 {
  font-size: 1.6rem;
  color: #2a9d8f;
  margin-bottom: 1rem;
}

/* Vendor List Carousel */
.vendor-list-container {
  display: flex;
  overflow-x: auto;
  gap: 1rem;
  padding: 1rem 0;
  scroll-behavior: smooth;
}

.vendor-card {
  flex: 0 0 auto;
  width: 140px; /* Mobile-first card size */
  background-color: #f8f9fa;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 1rem;
  text-align: center;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.vendor-card img {
  max-width: 100%;
  border-radius: 8px;
}

.vendor-card h3 {
  font-size: 1rem;
  margin-top: 0.8rem;
  color: #2a9d8f;
}

.vendor-card p {
  font-size: 0.9rem;
  color: #555;
}

/* Map Section */
.map-section {
  padding: 2rem 1rem;
  background-color: #e9ecef;
  text-align: center;
  width: 100%;
}

.map-section h2 {
  font-size: 1.6rem;
  color: #2a9d8f;
  margin-bottom: 1rem;
  font-weight: bold;
}

.map-preview {
  width: 150px;
  height: 150px;
  margin: 0 auto 1.5rem;
  background: linear-gradient(135deg, #2a9d8f, #21867a); /* Gradient border */
  border-radius: 50%; /* Circular design */
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Slight shadow for depth */
  position: relative;
}

.map-preview::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: 8px; /* Inner padding for the globe */
  background-color: #fff; /* White background for globe area */
  border-radius: 50%;
}

.map-placeholder {
  font-size: 2.5rem; /* Larger emoji size */
  z-index: 1; /* Ensure it’s above the white circle */
  color: #2a9d8f; /* Matches theme color */
}

.map-button {
  padding: 0.8rem 2rem;
  background-color: #2a9d8f;
  color: white;
  border: none;
  border-radius: 8px;
  font-size: 1rem;
  font-weight: bold;
  text-decoration: none;
  transition: background-color 0.3s, transform 0.2s ease;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Subtle shadow */
  cursor: pointer;
}

.map-button:hover {
  background-color: #21867a;
  transform: scale(1.05); /* Slight hover zoom */
}

/* Footer */
.footer {
  background-color: #f1f1f1;
  padding: 1rem;
  text-align: center;
  font-size: 0.9rem;
  color: #666;
  width: 100%;
}

/* Responsive Styles */
@media (min-width: 768px) {
  /* Hero Section */
  .hero-section {
    height: 60vh; /* Slightly taller for desktop */
  }

  .hero-content h1 {
    font-size: 2.5rem; /* Larger heading for desktop */
  }

  .hero-content p {
    font-size: 1.2rem;
  }

  .signup-button {
    font-size: 1.1rem;
  }

  /* Vendor List Section */
  .vendor-card {
    width: 200px; /* Larger cards for desktop */
  }

  /* Map Section */
  .map-preview {
    width: 200px;
    height: 200px;
  }

  .map-section h2 {
    font-size: 1.8rem;
  }

  .map-button {
    font-size: 1.1rem;
  }
}
