/* General Layout */
.contact-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2rem;
  background-color: #ffffff;
  min-height: 100vh; /* Full viewport height */
  font-family: 'Arial', sans-serif;
}

/* Title Styling */
.contact-title {
  font-size: 2rem;
  font-weight: bold;
  color: #2a9d8f;
  margin-bottom: 2rem;
  text-align: center;
}

/* Form Container */
.contact-form {
  max-width: 500px;
  width: 100%;
  background-color: #f9f9f9; /* Light background for the form */
  padding: 1.5rem;
  border-radius: 8px; /* Rounded corners */
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
  display: flex;
  flex-direction: column;
  gap: 1rem; /* Spacing between form fields */
}

/* Label Styling */
.contact-form label {
  font-size: 1rem;
  font-weight: bold;
  color: #333;
}

/* Input and Textarea Styling */
.contact-form input,
.contact-form textarea {
  width: 100%;
  padding: 0.75rem;
  font-size: 1rem;
  border: 1px solid #ddd;
  border-radius: 4px;
  outline: none;
  transition: border-color 0.3s ease, box-shadow 0.3s ease;
}

.contact-form input:focus,
.contact-form textarea:focus {
  border-color: #2a9d8f;
  box-shadow: 0 0 5px rgba(42, 157, 143, 0.3);
}

.contact-form textarea {
  resize: none;
  height: 120px;
}

/* Submit Button Styling */
.contact-form button {
  width: 100%;
  padding: 0.75rem;
  font-size: 1rem;
  font-weight: bold;
  color: #fff;
  background-color: #2a9d8f;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.contact-form button:hover {
  background-color: #21867a;
}

.contact-form button:active {
  background-color: #1a6c61;
  transform: scale(0.98); /* Slight scale effect on click */
}

/* Responsive Styling */
@media (max-width: 768px) {
  .contact-title {
    font-size: 1.8rem;
  }

  .contact-form {
    padding: 1rem;
    gap: 0.75rem;
  }

  .contact-form button {
    font-size: 0.9rem;
    padding: 0.6rem;
  }
}
