/* General Styling */
body {
  margin: 0;
  padding: 0;
  font-family: 'Arial', sans-serif;
  background-color: #f8f9fa;
  overflow-x: hidden;
}

.become-vendor-screen {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; /* Full-screen height */
  background-color: #f8f9fa;
}

.vendor-form {
  width: 100%;
  max-width: 420px; /* Adjust to make it more compact */
  background-color: white;
  border-radius: 16px;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.1); /* Soft modern shadow */
  padding: 2rem;
  text-align: center;
}

.vendor-form h1 {
  font-size: 1.8rem;
  color: #2a9d8f;
  margin-bottom: 1rem;
  font-weight: 700;
}

.vendor-form label {
  display: block;
  text-align: left;
  font-weight: bold;
  color: #333;
  margin: 12px 0 6px;
  font-size: 0.9rem;
}

.vendor-form input,
.vendor-form textarea {
  width: 100%;
  padding: 12px 14px;
  margin-bottom: 15px;
  border: 1px solid #ddd;
  border-radius: 10px;
  font-size: 1rem;
  box-sizing: border-box;
  outline: none;
  transition: border 0.3s, box-shadow 0.3s;
}

.vendor-form input:focus,
.vendor-form textarea:focus {
  border-color: #2a9d8f;
  box-shadow: 0 0 6px rgba(42, 157, 143, 0.4);
}

.vendor-form textarea {
  height: 100px;
  resize: none;
}

/* Phone Input Section */
.phone-input {
  display: flex;
  align-items: center;
  gap: 10px;
}

.country-code-dropdown {
  position: relative;
  display: flex;
  align-items: center;
}

.dropdown-toggle {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 12px;
  background-color: #f8f9fa;
  border: 1px solid #ddd;
  border-radius: 8px;
  font-size: 1rem;
  color: #333;
  cursor: pointer;
  width: 80px; /* Fixed width for consistency */
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  background-color: white;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  z-index: 10;
  max-height: 200px;
  overflow-y: auto;
  width: 200px;
}

.dropdown-item {
  padding: 10px;
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 0.9rem;
  cursor: pointer;
}

.dropdown-item:hover {
  background-color: #f1f1f1;
}

/* Submit Button */
.submit-button {
  width: 100%;
  padding: 12px;
  background-color: #2a9d8f;
  color: white;
  border: none;
  border-radius: 10px;
  font-size: 1rem;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.2s;
}

.submit-button:hover {
  background-color: #21867a;
  transform: scale(1.05);
}

.submit-button:disabled {
  background-color: #ccc;
  color: #999;
  cursor: not-allowed;
  transform: none;
}

/* Responsive Design */
@media (max-width: 768px) {
  .vendor-form {
    padding: 1.5rem;
  }

  .vendor-form h1 {
    font-size: 1.5rem;
  }

  .vendor-form input,
  .vendor-form textarea {
    font-size: 0.9rem;
  }

  .submit-button {
    font-size: 0.9rem;
  }

  .dropdown-menu {
    width: 180px;
  }
}
