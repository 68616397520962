.vendor-card {
    display: flex;
    flex-direction: column;
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s, box-shadow 0.3s;
    cursor: pointer;
  }
  
  .vendor-card:hover {
    transform: scale(1.05);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }
  
  .vendor-card-image {
    width: 100%;
    height: 150px;
    object-fit: cover;
  }
  
  .vendor-card-content {
    padding: 1rem;
  }
  
  .vendor-card-name {
    font-size: 1.2rem;
    font-weight: bold;
    margin-bottom: 0.5rem;
    color: #2a9d8f;
  }
  
  .vendor-card-description {
    font-size: 0.9rem;
    color: #666;
  }
  