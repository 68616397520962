/* Mapbox popup container overrides */
.mapboxgl-popup-content {
    background: transparent !important; /* Removes the default white background */
    border: none !important; /* Removes the default border */
    box-shadow: none !important; /* Removes the default shadow */
    padding: 0 !important; /* Removes the padding */
}

.mapboxgl-popup-close-button {
    display: none !important; /* Hides the close button */
}

/* Popup card container */
.popup-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding: 16px;
    background: rgba(255, 255, 255, 0.9); /* Semi-transparent white for subtle contrast */
    border-radius: 16px;
    overflow: hidden;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2); /* Soft shadow */
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    cursor: pointer;
    max-width: 260px;
    text-align: center;
    border: none; /* Removes border for a cleaner design */
}

.popup-card:hover {
    transform: translateY(-5px); /* Adds slight lift effect */
    box-shadow: 0 12px 24px rgba(0, 0, 0, 0.3); /* Stronger shadow on hover */
}

/* Image Container for Popup Card */
.popup-card-image-container {
    width: 100%;
    height: 140px;
    background-color: #f0f0f0; /* Placeholder background color */
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    border-radius: 12px 12px 0 0; /* Rounded corners for the top */
}

.popup-card-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

/* Content Section */
.popup-card-content {
    padding: 16px;
}

/* Title Styling */
.popup-card-title {
    font-size: 1.4rem;
    font-weight: 700;
    color: #2a9d8f; /* Matches the app theme */
    margin: 8px 0;
    line-height: 1.3;
    text-align: center;
}

/* Description Styling */
.popup-card-description {
    font-size: 1rem;
    color: #555;
    margin: 0;
    line-height: 1.5;
    text-align: center;
}

/* Responsive Design */

/* Tablet and Small Devices */
@media (max-width: 768px) {
    .popup-card {
        max-width: 220px;
    }

    .popup-card-title {
        font-size: 1.2rem;
    }

    .popup-card-description {
        font-size: 0.9rem;
    }

    .popup-card-image-container {
        height: 120px;
    }
}

/* Mobile Devices */
@media (max-width: 480px) {
    .popup-card {
        max-width: 180px;
    }

    .popup-card-title {
        font-size: 1rem;
    }

    .popup-card-description {
        font-size: 0.85rem;
    }

    .popup-card-image-container {
        height: 100px;
    }
}
