/* Vendor List Container */
.vendor-list-container {
  position: relative;
  margin-top: 1rem;
  padding: 1rem;
  overflow: hidden;
}

/* Carousel Styling */
.vendor-carousel {
  display: flex;
  overflow-x: auto;
  scroll-behavior: smooth;
  gap: 1rem;
  padding-bottom: 1rem;
}

.vendor-carousel::-webkit-scrollbar {
  height: 8px;
}

.vendor-carousel::-webkit-scrollbar-thumb {
  background-color: #2a9d8f;
  border-radius: 4px;
}

/* Vendor Card Styling */
.vendor-card {
  flex: 0 0 250px; /* Fixed width for each card */
  background-color: #f8f9fa;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 1rem;
  text-align: center;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s ease;
}

.vendor-card:hover {
  transform: translateY(-5px);
}

.vendor-card img {
  max-width: 100%;
  height: auto;
  border-radius: 8px;
}

.vendor-card h3 {
  margin-top: 1rem;
  color: #2a9d8f;
  font-size: 1.2rem;
}

.vendor-card p {
  font-size: 0.9rem;
  color: #666;
  margin-top: 0.5rem;
}

/* Carousel Arrows */
.carousel-arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: #fff;
  border: none;
  border-radius: 50%;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  width: 40px;
  height: 40px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.5rem;
  color: #2a9d8f;
  z-index: 10;
}

.carousel-arrow:hover {
  background-color: #2a9d8f;
  color: #fff;
}

.carousel-arrow.left {
  left: 10px;
}

.carousel-arrow.right {
  right: 10px;
}

/* Non-Carousel View */
.vendor-button-container {
  margin-top: 1rem;
  text-align: center;
}

.toggle-button {
  background-color: #2a9d8f;
  color: #fff;
  padding: 0.6rem 1.2rem;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1rem;
  transition: background-color 0.3s;
}

.toggle-button:hover {
  background-color: #21867a;
}
